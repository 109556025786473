import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

import { useStaticQuery, graphql } from "gatsby"

const InsurancesPage = (props) => {
    const data = useStaticQuery(graphql`
    query {
      Insurance: file(name: { eq: "Insurance" }) {
            publicURL
            name
      }
      SpanishInsurance: file(name: { eq: "es_insurance" }) {
            publicURL
            name
      }      
      Welcome: file(name: { eq: "Welcome to Our Office" }) {
            publicURL
            name
      }
      WelcomeBack: file(name: { eq: "Welcome Back" }) {
        publicURL
        name
  }      
      Dependentconsentform: file(name: { eq: "dependentconsentform" }) {
            publicURL
            name
      }
    }
  `)
    return (
        <Layout >
            <SEO title={'Download forms'} />
            <div className="px-4 mx-auto max-w-3xl text-navy-500 text-left lg:pt-8">
                <h1 className="text-center uppercase text-xl lg:my-2 font-bold my-4 lg:mb-12">Download Forms</h1>
                <p className=" mb-4 lg:mb-12" >
                    <span className="font-bold mr-2">This is a medical optometry practice.</span>
                    Please take a moment to look over our office forms. Filling them out prior to your appointment can expedite your time in with our doctors. Please contact us if you have absolutely any questions or need help.
                </p>
                <a className="flex mb-3 items-center block" href="https://hipaa.jotform.com/202545338678161" download >
                    <svg class="fill-current w-6 h-6" viewBox="0 0 20 20">
						<path d="M18.303,4.742l-1.454-1.455c-0.171-0.171-0.475-0.171-0.646,0l-3.061,3.064H2.019c-0.251,0-0.457,0.205-0.457,0.456v9.578c0,0.251,0.206,0.456,0.457,0.456h13.683c0.252,0,0.457-0.205,0.457-0.456V7.533l2.144-2.146C18.481,5.208,18.483,4.917,18.303,4.742 M15.258,15.929H2.476V7.263h9.754L9.695,9.792c-0.057,0.057-0.101,0.13-0.119,0.212L9.18,11.36h-3.98c-0.251,0-0.457,0.205-0.457,0.456c0,0.253,0.205,0.456,0.457,0.456h4.336c0.023,0,0.899,0.02,1.498-0.127c0.312-0.077,0.55-0.137,0.55-0.137c0.08-0.018,0.155-0.059,0.212-0.118l3.463-3.443V15.929z M11.241,11.156l-1.078,0.267l0.267-1.076l6.097-6.091l0.808,0.808L11.241,11.156z"></path>
                    </svg>
                    <span>New & previous patient info form - Suwanee office</span>
                </a>
                <a className="flex mb-3 items-center block" href="https://hipaa.jotform.com/212735462073049" download >
                    <svg class="fill-current w-6 h-6" viewBox="0 0 20 20">
						<path d="M18.303,4.742l-1.454-1.455c-0.171-0.171-0.475-0.171-0.646,0l-3.061,3.064H2.019c-0.251,0-0.457,0.205-0.457,0.456v9.578c0,0.251,0.206,0.456,0.457,0.456h13.683c0.252,0,0.457-0.205,0.457-0.456V7.533l2.144-2.146C18.481,5.208,18.483,4.917,18.303,4.742 M15.258,15.929H2.476V7.263h9.754L9.695,9.792c-0.057,0.057-0.101,0.13-0.119,0.212L9.18,11.36h-3.98c-0.251,0-0.457,0.205-0.457,0.456c0,0.253,0.205,0.456,0.457,0.456h4.336c0.023,0,0.899,0.02,1.498-0.127c0.312-0.077,0.55-0.137,0.55-0.137c0.08-0.018,0.155-0.059,0.212-0.118l3.463-3.443V15.929z M11.241,11.156l-1.078,0.267l0.267-1.076l6.097-6.091l0.808,0.808L11.241,11.156z"></path>
                    </svg>
                    <span>New & previous patient info form - Commerce office</span>
                </a>
	            {/* 
                    <a className="flex mb-3 items-center block" href={data.Insurance.publicURL}  download >
                    <svg className="fill-current w-6 h-6" viewBox="0 0 24 24">
                    <path d="M5,20H19V18H5M19,9H15V3H9V9H5L12,16L19,9Z" />
                    </svg>
                    <span>U & M Family Eyecare Insurance Form</span>
                    </a>
	             */}
	            <a className="flex mb-3 items-center block" href={data.Dependentconsentform.publicURL}  download >
                    <svg className="fill-current w-6 h-6" viewBox="0 0 24 24">
                        <path d="M5,20H19V18H5M19,9H15V3H9V9H5L12,16L19,9Z" />
                    </svg>
                    <span>Dependent Consent Form</span>
                </a>
            <a className="flex mb-3 items-center block" href={data.SpanishInsurance.publicURL}  download >
                <svg className="fill-current w-6 h-6" viewBox="0 0 24 24">
                    <path d="M5,20H19V18H5M19,9H15V3H9V9H5L12,16L19,9Z" />
                </svg>
                <span>Patient Form (Spanish)</span>
            </a>        
        </div>
        </Layout>
    )
}

export default InsurancesPage
